import axios from 'axios';
import {config} from '@/config';
import store from '@/store';

export default class TecinWebClient {
  http = axios.create({baseURL: config.http.baseUrl_api2});

  computers = {
    list: async () => {
      const {data} = await this.http.get('computers');
      return data;
    },
    find: async (id) => {
      const {data} = await this.http.get(`computers/${id}`);
      return data;
    },
    intertOrUpdate: async (id = null, patrimonio = null, setor = null, local = null, operador = null, tipo = null, marca = null, processador = null, memoria = null, armazenamento = null, ip = null, anydesk = null, benchmark_cinebench_r15 = null) => {
      const formData = new FormData();
      formData.append('patrimonio', patrimonio);
      formData.append('setor', setor ? setor : '');
      formData.append('local', local ? local : '');
      formData.append('operador', operador ? operador : '');
      formData.append('tipo', tipo ? tipo : '');
      formData.append('marca', marca ? marca : '');
      formData.append('processador', processador ? processador : '');
      formData.append('memoria', memoria ? memoria : '');
      formData.append('armazenamento', armazenamento ? armazenamento : '');
      formData.append('ip', ip ? ip : '');
      formData.append('benchmark_cinebench_r15', benchmark_cinebench_r15 ? benchmark_cinebench_r15 : '');
      formData.append('anydesk', anydesk ? anydesk : '');
      await this.http.post(id ? `computers/${id}` : 'computers', formData);
    },
    delete: async (id) => await this.http.delete(`computers/${id}`),
  }

  printers = {
    list: async () => {
      const {data} = await this.http.get('printers');
      return data;
    },
    find: async (id) => {
      const {data} = await this.http.get(`printers/${id}`);
      return data;
    },
    intertOrUpdate: async (id = null, patrimonio = null, setor = null, local = null, marca = null, modelo = null, ip = null) => {
      const formData = new FormData();
      if (patrimonio) formData.append('patrimonio', patrimonio);
      if (setor) formData.append('setor', setor);
      if (local) formData.append('local', local);
      if (marca) formData.append('marca', marca);
      if (modelo) formData.append('modelo', modelo);
      if (ip) formData.append('ip', ip);
      await this.http.post(id ? `printers/${id}` : 'printers', formData);
    },
    delete: async (id) => await this.http.delete(`printers/${id}`),
  }

  technicalSchemes = {
    list: async () => {
      const {data} = await this.http.get('technicalschemes');
      return data;
    },
    download: async (id) => {
      return await this.http.get(`technicalschemes/${id}`);
    },
    intertOrUpdate: async (id = null, title = null, file = null) => {
      const formData = new FormData();
      if (title) formData.append('title', title);
      if (file) formData.append('file', file);
      await this.http.post(id ? `technicalschemes/${id}` : 'technicalschemes', formData);
    },
    delete: async (id) => await this.http.delete(`technicalschemes/${id}`),
  }

  consumables = {
    list: async () => {
      const {data} = await this.http.get('consumables');
      return data;
    },
    find: async (id) => {
      const {data} = await this.http.get(`consumables/${id}`);
      return data;
    },
    intertOrUpdate: async (id = null, name, amount = 0, note = '') => {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('amount', amount);
      formData.append('note', note);
      formData.append('user_cpf', store.getters['session/payload'].cpf);
      await this.http.post(id ? `consumables/${id}` : 'consumables', formData);
    },
    delete: async (id) => await this.http.delete(`consumables/${id}`),
  }

  companyassets = {
    list: async () => {
      const {data} = await this.http.get('companyassets');
      return data;
    },
    find: async (id) => {
      const {data} = await this.http.get(`companyassets/${id}`);
      return data;
    },
    intertOrUpdate: async (id = null, patrimonio, produto) => {
      const formData = new FormData();
      if (patrimonio) formData.append('patrimonio', patrimonio);
      if (produto) formData.append('produto', produto);
      await this.http.post(id ? `companyassets/${id}` : 'companyassets', formData);
    },
    delete: async (id) => await this.http.delete(`companyassets/${id}`),
  }
}
