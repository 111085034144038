<template>
  <async-container :loading="loading" fluid>
    <v-card>
      <v-card-title style="border-bottom: 1px dashed rgba(0,0,0,.25)" class="mb-3">
        Computadores
        <v-spacer></v-spacer>
        <v-btn color="primary" small outlined @click="dialogAdd = true">
          <v-icon>mdi-plus</v-icon>
          Adicionar
        </v-btn>
        <v-btn color="primary" small class="ml-1" target="_blank"
               :href="config.http.baseUrl + 'public/computadores.php'">
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Filtro"
          v-model="iptSearch"
          placeholder="Digite algo para filtrar"
          prepend-inner-icon="mdi-magnify"
          hide-details
          solo-inverted
          dense
        ></v-text-field>
      </v-card-text>
      <v-data-table
        :headers="tableHeaders"
        :items="tableItems"
        :loading="loadingTable"
        :search="iptSearch"
        dense
        no-data-text="Nenhum computador cadastrado"
        no-results-text="Nenhum computador encontrado"
        :items-per-page="10"
        :footer-props="{'items-per-page-options': [10, 20, 50, -1]}"
      >
        <template v-slot:item.actions="{item}">
          <div class="d-flex flex-nowrap justify-center">
            <v-btn small icon color="amber" :disabled="loadingTable" @click="loadInputs(item.id)" title="Editar">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn small icon color="red" :disabled="loadingTable" @click="deleteComputador(item.id)" title="Remover">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogAdd" :persistent="loadingForms" fullscreen>
      <v-card>
        <v-form @submit.prevent="insertOrUpdateComputador" :disabled="loadingForms" ref="form-add">
          <v-card-title>{{ iptId ? 'Alterar computador' : 'Registrar computador' }}</v-card-title>
          <v-card-text class="pb-0">
            <v-row dense>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Patrimonio"
                  dense
                  v-model="iptPatrimonio"
                  persistent-hint
                  hint="Número de patrimonio"
                  outlined
                  type="tel"
                  :rules="[rules.numberRule, patrimonioRule]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Setor"
                  dense
                  v-model="iptSetor"
                  persistent-hint
                  hint="COGEP, COGEA, LICOC, TECIN..."
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Local"
                  dense
                  v-model="iptLocal"
                  persistent-hint
                  hint="Coordenadoria, Recepção..."
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Operador"
                  dense
                  v-model="iptOperador"
                  persistent-hint
                  hint="Nome do usuário, deixe vazio se não houver um específico"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Tipo"
                  dense
                  v-model="iptTipo"
                  persistent-hint
                  hint="Desktop, Laptop, All-in-one..."
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Marca"
                  dense
                  v-model="iptMarca"
                  persistent-hint
                  hint="Asus, Acer, Dell, Positivo..."
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Processador"
                  dense
                  v-model="iptProcessador"
                  class="mb-1"
                  persistent-hint
                  hint="Intel i5-5200U, AMD Ryzen 3200G..."
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Memória"
                  dense
                  v-model="iptMemoria"
                  class="mb-1"
                  persistent-hint
                  hint="8GB DDR4, 16GB DDR3L (8+8)"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Armazenamento"
                  dense
                  v-model="iptArmazenamento"
                  class="mb-1"
                  persistent-hint
                  hint="500GB HD, 240GB SSD"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="IP"
                  dense
                  v-model="iptIp"
                  persistent-hint
                  hint="192.168.0.1"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  label="AnyDesk"
                  dense
                  v-model="iptAnydesk"
                  persistent-hint
                  hint="Endereço AnyDesk para acesso remoto"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="primary" small :loading="loadingForms" type="submit">Salvar</v-btn>
            <v-btn color="primary" small outlined :disabled="loadingForms" @click="dialogAdd = false">Cancelar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </async-container>
</template>

<script>
  import AsyncContainer from '../../components/interface/AsyncContainer';
  import TecinWebClient from '../../http/TecinWebClient';
  import {config} from '@/config';
  export default {
    name: 'Computadores',
    components: {AsyncContainer},
    data: () => ({
      config,
      loading: true,
      loadingForms: false,
      loadingTable: false,
      tableHeaders: [
        {value: 'patrimonio', text: 'Patrimonio'},
        {value: 'setor', text: 'Setor'},
        {value: 'local', text: 'Local'},
        {value: 'operador', text: 'Operador'},
        {value: 'tipo', text: 'Tipo'},
        {value: 'marca', text: 'Marca'},
        {value: 'processador', text: 'Processador'},
        {value: 'memoria', text: 'Memoria'},
        {value: 'armazenamento', text: 'Armazenamento'},
        {value: 'ip', text: 'IP'},
        {value: 'actions', text: 'Ações', align: 'center', sortable: false},
      ],
      tableItems: [],
      webClient: new TecinWebClient(),
      dialogAdd: false,
      //inputs
      iptId: null,
      iptPatrimonio: '',
      iptSetor: '',
      iptLocal: '',
      iptOperador: '',
      iptTipo: '',
      iptMarca: '',
      iptProcessador: '',
      iptMemoria: '',
      iptArmazenamento: '',
      iptIp: '',
      iptAnydesk: '',
      iptSearch: '',
      rules: {
        numberRule: v => {
          if (!v.trim()) return true;
          if (!v.replace(/\d/g, '')) return true;
          return 'O patrimonio deve conter apenas números';
        },
      },
    }),
    methods: {
      async loadData() {
        try {
          this.tableItems = await this.webClient.computers.list();
          this.loading = false;
        } catch (e) {
          this.$router.push('/tecin');
        }
      },
      async insertOrUpdateComputador() {
        if (!this.$refs['form-add'].validate()) {
          this.$alert({text: 'Há erros no formulário', type: 'error'});
          return;
        }
        if (!this.iptPatrimonio && !this.iptSetor && !this.iptLocal && !this.iptOperador && !this.iptTipo && !this.iptMarca && !this.iptProcessador && !this.iptMemoria && !this.iptArmazenamento && !this.iptIp && !this.iptAnydesk) {
          this.$alert({text: 'O formulário está vazio', type: 'error'});
          return;
        }
        this.loadingForms = true;
        try {
          await this.webClient.computers.intertOrUpdate(this.iptId, this.iptPatrimonio, this.iptSetor, this.iptLocal, this.iptOperador, this.iptTipo, this.iptMarca, this.iptProcessador, this.iptMemoria, this.iptArmazenamento, this.iptIp, this.iptAnydesk);
          await this.loadData();
          this.dialogAdd = false;
        } finally {
          this.loadingForms = false;
        }
      },
      async deleteComputador(id) {
        if (!window.confirm('Tem certeza que deseja remover este computador?')) return;
        this.loadingTable = true;
        try {
          await this.webClient.computers.delete(id);
          await this.loadData();
        } finally {
          this.loadingTable = false;
        }
      },
      loadInputs(id) {
        const item = this.tableItems.find(i => i.id === id);
        this.iptId = item.id;
        this.iptPatrimonio = item.patrimonio ? item.patrimonio.toString() : '';
        this.iptSetor = item.setor;
        this.iptLocal = item.local;
        this.iptOperador = item.operador;
        this.iptTipo = item.tipo;
        this.iptMarca = item.marca;
        this.iptProcessador = item.processador;
        this.iptMemoria = item.memoria;
        this.iptArmazenamento = item.armazenamento;
        this.iptIp = item.ip;
        this.iptAnydesk = item.anydesk;
        this.dialogAdd = true;
      },
      patrimonioRule(v) {
        if (!v) return true;
        const item = this.tableItems.find(i => (i.patrimonio && i.patrimonio.toString() === v) && (!this.iptId || this.iptId !== i.id));
        return item ? 'Este número de patrimonio está em uso' : true;
      },
    },
    created() {
      this.loadData();
    },
    watch: {
      dialogAdd(v) {
        if (v === false) {
          this.iptId = null;
          this.iptPatrimonio = '';
          this.iptSetor = '';
          this.iptLocal = '';
          this.iptOperador = '';
          this.iptTipo = '';
          this.iptMarca = '';
          this.iptProcessador = '';
          this.iptMemoria = '';
          this.iptArmazenamento = '';
          this.iptIp = '';
          this.iptAnydesk = '';
        }
      },
    },
  }
</script>
