<template>
  <async-container :loading="loading" fluid>
    <v-card>
      <v-card-title style="border-bottom: 1px dashed rgba(0,0,0,.25)" class="mb-3">
        Patrimônios
        <v-spacer></v-spacer>
        <v-btn color="primary" small outlined @click="dialogAdd = true">
          <v-icon>mdi-plus</v-icon>
          Adicionar
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Filtro"
          v-model="iptSearch"
          placeholder="Digite algo para filtrar"
          prepend-inner-icon="mdi-magnify"
          hide-details
          solo-inverted
          dense
        ></v-text-field>
      </v-card-text>
      <v-data-table
        :headers="tableHeaders"
        :items="tableItems"
        :loading="loadingTable"
        :search="iptSearch"
        dense
        no-data-text="Nenhum item cadastrado"
        no-results-text="Nenhum item encontrado"
        :items-per-page="10"
        :footer-props="{'items-per-page-options': [10, 20, 50, -1]}"
      >
        <template v-slot:item.actions="{item}">
          <div class="d-flex flex-nowrap justify-center">
            <v-btn small icon color="amber" :disabled="loadingTable" @click="loadInputs(item.id)" title="Editar">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn small icon color="red" :disabled="loadingTable" @click="remove(item.id)" title="Remover">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialogAdd"
      :persistent="loadingForms"
      width="480"
      max-width="94%"
      >
      <v-card>
        <v-form @submit.prevent="insertOrUpdate" :disabled="loadingForms" ref="form-add">
          <v-card-title>{{ iptId ? 'Alterar item' : 'Registrar item' }}</v-card-title>
          <v-card-text class="pb-0">
            <v-text-field
              label="Patrimonio"
              dense
              v-model="iptPatrimonio"
              persistent-hint
              hint="Número de patrimonio"
              outlined
              type="tel"
              :rules="[rules.numberRule, patrimonioRule]"
            ></v-text-field>
            <v-text-field
              label="Produto"
              dense
              v-model="iptProduto"
              persistent-hint
              hint="Cadeira, Mesa..."
              outlined
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="primary" small :loading="loadingForms" type="submit">Salvar</v-btn>
            <v-btn color="primary" small outlined :disabled="loadingForms" @click="dialogAdd = false">Cancelar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      </v-dialog>
  </async-container>
</template>

<script>
  import AsyncContainer from '../../components/interface/AsyncContainer';
  import TecinWebClient from '../../http/TecinWebClient';
  export default {
    name: 'Patrimonios',
    components: {AsyncContainer},
    data: () => ({
      loading: true,
      loadingForms: false,
      loadingTable: false,
      tableHeaders: [
        {value: 'patrimonio', text: 'Patrimonio'},
        {value: 'produto', text: 'Produto'},
        {value: 'actions', text: 'Ações', align: 'center', sortable: false},
      ],
      tableItems: [],
      webClient: new TecinWebClient(),
      dialogAdd: false,
      //inputs
      iptId: null,
      iptPatrimonio: '',
      iptProduto: '',
      iptSearch: '',
      rules: {
        numberRule: v => {
          if (!v.trim()) return true;
          if (!v.replace(/\d/g, '')) return true;
          return 'O patrimonio deve conter apenas números';
        },
      },
    }),
    methods: {
      async loadData() {
        try {
          this.tableItems = await this.webClient.companyassets.list();
          this.loading = false;
        } catch (e) {
          this.$router.push('/tecin');
        }
      },
      async insertOrUpdate() {
        if (!this.$refs['form-add'].validate()) {
          this.$alert({text: 'Há erros no formulário', type: 'error'});
          return;
        }
        if (!this.iptPatrimonio && !this.iptProduto) {
          this.$alert({text: 'O formulário está vazio', type: 'error'});
          return;
        }
        this.loadingForms = true;
        try {
          await this.webClient.companyassets.intertOrUpdate(this.iptId, this.iptPatrimonio, this.iptProduto);
          await this.loadData();
          this.dialogAdd = false;
        } finally {
          this.loadingForms = false;
        }
      },
      async remove(id) {
        if (!window.confirm('Tem certeza que deseja remover este item?')) return;
        this.loadingTable = true;
        try {
          await this.webClient.companyassets.delete(id);
          await this.loadData();
        } finally {
          this.loadingTable = false;
        }
      },
      loadInputs(id) {
        const item = this.tableItems.find(i => i.id === id);
        this.iptId = item.id;
        this.iptPatrimonio = item.patrimonio ? item.patrimonio.toString() : '';
        this.iptProduto = item.produto;
        this.dialogAdd = true;
      },
      patrimonioRule(v) {
        if (!v) return true;
        const item = this.tableItems.find(i => (i.patrimonio && i.patrimonio.toString() === v) && (!this.iptId || this.iptId !== i.id));
        return item ? 'Este número de patrimonio está em uso' : true;
      },
    },
    created() {
      this.loadData();
    },
    watch: {
      dialogAdd(v) {
        if (v === false) {
          this.iptId = null;
          this.iptPatrimonio = '';
          this.iptProduto = '';
        }
      },
    },
  }
</script>
