<template>
  <async-container :loading="loading" fluid>
    <v-card>
      <v-card-title style="border-bottom: 1px dashed rgba(0,0,0,.25)" class="mb-3">
        Esquemas
        <v-spacer></v-spacer>
        <v-btn color="primary" small outlined @click="dialogAdd = true">
          <v-icon>mdi-plus</v-icon>
          Adicionar
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Filtro"
          v-model="iptSearch"
          placeholder="Digite algo para filtrar"
          prepend-inner-icon="mdi-magnify"
          hide-details
          solo-inverted
          dense
        ></v-text-field>
      </v-card-text>
      <v-data-table
        :headers="tableHeaders"
        :items="tableItems"
        :loading="loadingTable"
        :search="iptSearch"
        no-data-text="Nenhum esquema cadastrado"
        no-results-text="Nenhum esquema encontrado"
        :items-per-page="10"
        :footer-props="{'items-per-page-options': [10, 20, 50, -1]}"
        sort-by="title"
      >
        <template v-slot:item.extension="{item}">{{item.extension ? item.extension.toUpperCase() : ''}}</template>
        <template v-slot:item.created_at="{item}">{{dateFormat(item.created_at)}}</template>
        <template v-slot:item.actions="{item}">
          <div class="d-flex flex-nowrap justify-center">
            <v-btn
              small
              icon
              color="primary"
              :disabled="loadingTable || (downloadingItem && downloadingItem !== item.id)"
              @click="downloadItem(item.id)"
              title="Download"
              :loading="downloadingItem && downloadingItem === item.id"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
            <v-btn small icon color="amber" :disabled="loadingTable" @click="loadInputs(item.id)" title="Renomear">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn small icon color="red" :disabled="loadingTable" @click="remove(item.id)" title="Remover">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog width="500" max-width="94%" v-model="dialogAdd" :persistent="loadingForms">
      <v-card>
        <v-form @submit.prevent="insertOrUpdate" :disabled="loadingForms" ref="form-add">
          <v-card-title>{{ iptId ? 'Renomear esquema' : 'Registrar esquema' }}</v-card-title>
          <v-card-text class="pb-0">
            <v-text-field
              label="Título"
              dense
              v-model="iptTitulo"
              outlined
              prepend-icon="mdi-format-letter-case-lower"
              :rules="[rules.titleRule]"
            ></v-text-field>
            <v-file-input
              v-if="!iptId"
              label="Arquivo"
              dense
              v-model="iptArquivo"
              outlined
              placeholder="Insira um arquivo"
              :rules="[rules.fileRule]"
            ></v-file-input>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="primary" small :loading="loadingForms" type="submit">Salvar</v-btn>
            <v-btn color="primary" small outlined :disabled="loadingForms" @click="dialogAdd = false">Cancelar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </async-container>
</template>

<script>
  import AsyncContainer from '../../components/interface/AsyncContainer';
  import TecinWebClient from '../../http/TecinWebClient';
  import moment from 'moment';
  export default {
    name: 'Esquemas',
    components: {AsyncContainer},
    data: () => ({
      loading: true,
      loadingForms: false,
      loadingTable: false,
      tableHeaders: [
        {value: 'title', text: 'Título'},
        {value: 'extension', text: 'Formato'},
        {value: 'created_at', text: 'Data', align: 'center'},
        {value: 'actions', text: 'Ações', align: 'center', sortable: false},
      ],
      tableItems: [],
      webClient: new TecinWebClient(),
      dialogAdd: false,
      //inputs
      iptId: null,
      iptTitulo: '',
      iptArquivo: null,
      iptSearch: '',
      rules: {
        titleRule: v => {
          if (!v.trim()) return 'O título está vazio';
          return true;
        },
        fileRule: v => !!v || 'O arquivo não foi anexado',
      },
      downloadingItem: false,
    }),
    methods: {
      async loadData() {
        try {
          this.tableItems = await this.webClient.technicalSchemes.list();
          this.loading = false;
        } catch (e) {
          this.$router.push('/tecin');
        }
      },
      async insertOrUpdate() {
        if (!this.$refs['form-add'].validate()) {
          this.$alert({text: 'Há erros no formulário', type: 'error'});
          return;
        }
        if (!this.iptTitulo) {
          this.$alert({text: 'O formulário está vazio', type: 'error'});
          return;
        }
        this.loadingForms = true;
        try {
          await this.webClient.technicalSchemes.intertOrUpdate(this.iptId, this.iptTitulo.trim(), this.iptArquivo);
          await this.loadData();
          this.dialogAdd = false;
        } finally {
          this.loadingForms = false;
        }
      },
      async remove(id) {
        if (!window.confirm('Tem certeza que deseja remover este esquema?')) return;
        this.loadingTable = true;
        try {
          await this.webClient.technicalSchemes.delete(id);
          await this.loadData();
        } finally {
          this.loadingTable = false;
        }
      },
      async downloadItem(id) {
        const item = this.tableItems.find(i => i.id === id);
        this.downloadingItem = id;
        const response = await this.webClient.technicalSchemes.download(id);
        const name = item.title.replace(/[^a-zA-Z\d]/g,'_').toLowerCase() + '.' + item.extension;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        link.click();
        this.downloadingItem = false;
        window.URL.revokeObjectURL(url);
      },
      loadInputs(id) {
        const item = this.tableItems.find(i => i.id === id);
        this.iptId = item.id;
        this.iptTitulo = item.title;
        this.iptArquivo = null;
        this.dialogAdd = true;
      },
      dateFormat(timestamp) {
        return moment(timestamp).format('DD/MM/YYYY HH:mm');
      },
    },
    created() {
      this.loadData();
    },
    watch: {
      dialogAdd(v) {
        if (v === false) {
          this.iptId = null;
          this.iptTitulo = '';
          this.iptArquivo = null;
        }
      },
    },
  }
</script>
