<template>
  <v-container>
    <p class="title mb-0">TECIN</p>
    <p class="subtitle-1">Ferramentas do setor de T.I.</p>
    <v-divider class="mb-5"/>
    <v-sheet v-for="i in itensSorted" :key="i.to" class="mb-3" outlined light rounded>
      <v-list-item :to="i.to">
        <v-list-item-icon>
          <v-icon>mdi-{{i.icon}}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{i.title}}</v-list-item-title>
          <v-list-item-subtitle>{{i.subtitle}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: 'TecinMenu',
  data: () => ({
    items: [
      {
        to: '/tecin/computadores',
        icon: 'lan-pending',
        title: 'Computadores',
        subtitle: 'Tabela de computadores do CRECI'
      },
      {
        to: '/tecin/impressoras',
        icon: 'printer',
        title: 'Impressoras',
        subtitle: 'Tabela de impressoras do CRECI'
      },
      {
        to: '/tecin/esquemas',
        icon: 'file-cad-box',
        title: 'Esquemas técnicos',
        subtitle: 'Esquemas & Desenhos'
      },
      {
        to: '/suporte/consumiveis',
        icon: 'cube-outline',
        title: 'Consumíveis',
        subtitle: 'Controle de itens de consumo do TECIN'
      },
      {
        to: '/suporte/patrimonios',
        icon: 'archive',
        title: 'Patrimônios',
        subtitle: 'Controle de patrimônios do TECIN'
      },
    ],
  }),
  computed: {
    itensSorted() {
      const items = this.items;
      return items.sort((a, b) => {
        if (a.title < b.title) return -1;
        if (a.title > b.title) return 1;
        return 0;
      });
    },
  },
}
</script>

<style scoped>

</style>