<template>
  <async-container :loading="!chamado">
    <v-card v-if="chamado" class="mb-5">
      <v-card-title class="title-bar justify-space-between">
        Chamado de suporte
        <v-chip v-if="chamado.prioridade === 1" color="error" label small>URGENTE</v-chip>
        <v-chip v-else-if="chamado.prioridade === 2" color="error" label small>URGENTÍSSIMO</v-chip>
      </v-card-title>
      <v-card-text>
        <div class="mt-3 text-center">
          <p class="title blue-grey--text text--darken-2 mb-0">{{chamado.titulo}}</p>
          <v-chip v-if="chamado.status === 0" small color="primary">EM ANDAMENTO</v-chip>
          <v-chip v-else-if="chamado.status === 1" small color="success">ENCERRADO</v-chip>
          <v-chip v-else-if="chamado.status === 2" small color="warning">ARQUIVADO</v-chip>
        </div>
        <v-divider class="my-3"></v-divider>
        <p class="subtitle-2 mb-0">Protocolo {{chamado.id}}</p>
        <p class="subtitle-2 mb-0">Aberto em {{formatarData(chamado.criacao)}}</p>
        <p class="subtitle-2 mb-0" v-if="chamado.encerramento">Encerrado em {{formatarData(chamado.encerramento)}}</p>
        <v-divider class="my-3"></v-divider>
        <p class="subtitle-2 mb-0">Descrição</p>
        <p class="body-2" style="white-space: pre-wrap">{{chamado.descricao}}</p>
        <div class="d-flex align-center">
          <v-avatar size="48" class="elevation-2">
            <img :src="avatarSrc(chamado.autor)" alt="">
          </v-avatar>
          <div class="ml-3">
            <p class="caption mb-1" style="line-height: 1">Autor</p>
            <p class="subtitle-2 mb-0" style="line-height: 1">{{shortName(chamado.autor_nome)}}</p>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="justify-center" style="border-top: 1px solid #dbdbdb">
        <v-btn color="success" small :loading="statusLoading" @click="mudarStatus(1)" v-if="chamado.status === 0">Encerrar chamado</v-btn>
        <v-btn color="primary" small :loading="statusLoading" @click="mudarStatus(0)" v-else-if="chamado.status !== 0">Reabrir chamado</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="chamado">
      <v-card-title class="title-bar">Comentários</v-card-title>
      <v-timeline dense class="mr-3">
        <v-timeline-item
          v-for="comentario in chamado.comentarios"
          :key="comentario.id"
        >
          <template v-slot:icon>
            <v-avatar class="elevation-2">
              <img :src="avatarSrc(comentario.autor)" alt="">
            </v-avatar>
          </template>
          <v-sheet outlined rounded class="pa-3">
            <p class="subtitle-2 mb-0">{{shortName(comentario.autor_nome)}}</p>
            <p class="ma-0" style="white-space: pre-wrap">{{comentario.texto}}</p>
            <p class="mb-0 caption">{{formatarData(comentario.datahora)}}</p>
          </v-sheet>
        </v-timeline-item>
      </v-timeline>
      <v-card-text class="pb-0 pt-1">
        <v-form ref="form-comentario">
          <v-textarea
            label="Novo comentário"
            outlined
            :rows="4"
            :rules="[rules.obrigatorio]"
            v-model="iptComentario"
            :disabled="comentarioLoading"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center justify-sm-end pt-0">
        <v-btn color="primary" outlined :loading="comentarioLoading" @click="enviarComentario">Enviar comentário</v-btn>
      </v-card-actions>
    </v-card>
  </async-container>
</template>

<script>
import SuporteWebClient from '@/http/SuporteWebClient';
import AsyncContainer from '@/components/interface/AsyncContainer';
import moment from 'moment';
import StringHelper from '@/helpers/StringHelper';
import UserWebClient from '@/http/UserWebClient';
export default {
  name: 'Chamado',
  components: {AsyncContainer},
  props: ['id'],
  data: () => ({
    SuporteWebClient: new SuporteWebClient(),
    UserWebClient: new UserWebClient(),
    chamado: null,
    rules: {
      obrigatorio: v => !!v || v === 0 || 'Se quer enviar um comentário, escreva o texto.',
    },
    iptComentario: '',
    comentarioLoading: false,
    statusLoading: false,
  }),
  methods: {
    async loadData() {
      try {
        this.chamado = await this.SuporteWebClient.getChamado(this.id);
      } catch (e) {
        this.$router.push('/suporte');
      }
    },
    formatarData(datetime) {
      return moment(datetime).format('DD/MM/YYYY HH:mm');
    },
    shortName(name) {
      return StringHelper.shortName(name);
    },
    avatarSrc(usuario) {
      return this.chamado ? this.UserWebClient.getUsuarioAvatarUrl(usuario) : '';
    },
    async enviarComentario() {
      if (!this.$refs['form-comentario'].validate()) return;
      const comentario = this.chamado.comentarios.find(c => c.texto.trim().toLowerCase() === this.iptComentario.trim().toLowerCase());
      if (comentario) {
        this.$snackbar({text: 'A mensagem está duplicada', color: 'error'});
        return;
      }
      this.comentarioLoading = true;
      try {
        await this.SuporteWebClient.insertChamadoComentario(this.id, this.iptComentario);
        await this.loadData();
        this.iptComentario = '';
        this.$snackbar({text: 'Comentário registrado', color: 'success'});
        this.$refs['form-comentario'].resetValidation()
      } finally {
        this.comentarioLoading = false;
      }
    },
    async mudarStatus(status) {
      this.statusLoading = true;
      try {
        await this.SuporteWebClient.updateChamadoStatus(this.id, status);
        await this.loadData();
      } finally {
        this.statusLoading = false;
      }
    },
  },
  created() {
    this.loadData();
  },
}
</script>

<style scoped>
  .title-bar {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dbdbdb !important;
    padding: .75rem;
    margin-bottom: 0;
  }
</style>