<template>
  <v-container>
    <p class="title mb-0">Suporte</p>
    <p class="subtitle-1">Interaja e acompanhe chamados de T.I.</p>
    <v-divider class="mb-5"/>
    <v-sheet v-for="i in items" :key="i.to" class="mb-3" outlined light rounded>
      <v-list-item :to="i.to">
        <v-list-item-icon>
          <v-icon>mdi-{{i.icon}}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{i.title}}</v-list-item-title>
          <v-list-item-subtitle>{{i.subtitle}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: 'Index',
  data: () => ({
    items: [
      {
        to: '/suporte/chamadoeditor',
        icon: 'clipboard-plus',
        title: 'Abrir um chamado',
        subtitle: 'Solicite suporte para tratar incidentes ou requisições'
      },
      {
        to: '/suporte/chamados',
        icon: 'clipboard-search',
        title: 'Meus chamados',
        subtitle: 'Acompanhe os seus chamados'
      },
      {
        to: '/suporte/fila',
        icon: 'clipboard-list',
        title: 'Fila de chamados',
        subtitle: 'Veja todos os chamados de T.I.'
      },
    ],
  }),
}
</script>

<style scoped>

</style>