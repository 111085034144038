<template>
  <async-container :loading="loading" fluid>
    <v-card>
      <v-card-title style="border-bottom: 1px dashed rgba(0,0,0,.25)" class="mb-3">
        Consumíveis
        <v-spacer></v-spacer>
        <v-btn color="primary" small outlined @click="dialogAdd = true">
          <v-icon>mdi-plus</v-icon>
          Adicionar
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Filtro"
          v-model="iptSearch"
          placeholder="Digite algo para filtrar"
          prepend-inner-icon="mdi-magnify"
          hide-details
          solo-inverted
          dense
        ></v-text-field>
      </v-card-text>
      <v-data-table
        :headers="tableHeaders"
        :items="tableItems"
        :loading="loadingTable"
        :search="iptSearch"
        dense
        no-data-text="Nenhum item cadastrado"
        no-results-text="Nenhum item encontrado"
        :items-per-page="10"
        :footer-props="{'items-per-page-options': [10, 20, 50, -1]}"
      >
        <template v-slot:item.updated_at="{item}">{{dateTimeFormat(item.updated_at)}}</template>
        <template v-slot:item.actions="{item}">
          <div class="d-flex flex-nowrap justify-center">
            <v-btn small icon color="amber" :disabled="loadingTable" @click="loadInputs(item.id)" title="Editar">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn small icon color="red" :disabled="loadingTable" @click="remove(item.id)" title="Remover">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogAdd" :persistent="loadingForms" width="480" max-width="94%">
      <v-card>
        <v-form @submit.prevent="insertOrUpdate" :disabled="loadingForms" ref="form-add">
          <v-card-title>{{ iptId ? 'Alterar objeto' : 'Registrar objeto' }}</v-card-title>
          <v-card-text class="pb-0">
            <v-text-field
              label="Nome"
              dense
              v-model="iptName"
              outlined
              :rules="[rules.requiredRule]"
            ></v-text-field>
            <v-text-field
              label="Quantidade"
              dense
              v-model="iptAmount"
              outlined
              type="tel"
              :rules="[rules.requiredRule, rules.numberRule]"
            ></v-text-field>
            <v-text-field
              label="Observação"
              dense
              v-model="iptNote"
              outlined
              hint="Deixe uma anotação para histórico"
              persistent-hint
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="primary" small :loading="loadingForms" type="submit">Salvar</v-btn>
            <v-btn color="primary" small outlined :disabled="loadingForms" @click="dialogAdd = false">Cancelar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </async-container>
</template>

<script>
  import AsyncContainer from '../../components/interface/AsyncContainer';
  import TecinWebClient from '../../http/TecinWebClient';
  import moment from 'moment';
  export default {
    name: 'Consumiveis',
    components: {AsyncContainer},
    data: () => ({
      loading: true,
      loadingForms: false,
      loadingTable: false,
      tableHeaders: [
        {value: 'name', text: 'Nome'},
        {value: 'amount', text: 'Quantidade'},
        {value: 'updated_at', text: 'Atualizado em'},
        {value: 'actions', text: 'Ações', align: 'center', sortable: false},
      ],
      tableItems: [],
      webClient: new TecinWebClient(),
      dialogAdd: false,
      //inputs
      iptId: null,
      iptName: '',
      iptAmount: '',
      iptNote: '',
      iptSearch: '',
      rules: {
        numberRule: v => {
          if (!v.trim()) return true;
          if (!v.replace(/\d/g,'')) return true;
          return 'O campo deve conter apenas números';
        },
        requiredRule: v => {
          if (!v || !v.trim()) return 'Este campo é obrigatório';
          else return true;
        },
      },
    }),
    methods: {
      async loadData() {
        try {
          this.tableItems = await this.webClient.consumables.list();
          this.loading = false;
        } catch (e) {
          this.$router.push('/tecin');
        }
      },
      async insertOrUpdate() {
        if (!this.$refs['form-add'].validate()) {
          this.$alert({text: 'Há erros no formulário', type: 'error'});
          return;
        }
        if (!this.iptName && !this.iptAmount && !this.iptNote) {
          this.$alert({text: 'O formulário está vazio', type: 'error'});
          return;
        }
        this.loadingForms = true;
        try {
          await this.webClient.consumables.intertOrUpdate(this.iptId, this.iptName, this.iptAmount, this.iptNote);
          await this.loadData();
          this.dialogAdd = false;
        } finally {
          this.loadingForms = false;
        }
      },
      async remove(id) {
        if (!window.confirm('Tem certeza que deseja remover este item?')) return;
        this.loadingTable = true;
        try {
          await this.webClient.consumables.delete(id);
          await this.loadData();
        } finally {
          this.loadingTable = false;
        }
      },
      loadInputs(id) {
        const item = this.tableItems.find(i => i.id === id);
        this.iptId = item.id;
        this.iptName = item.name;
        this.iptAmount = item.amount.toString();
        this.dialogAdd = true;
      },
      dateTimeFormat(timestamp) {
        return moment(timestamp).format('DD/MM/YYYY HH:mm');
      },
    },
    created() {
      this.loadData();
    },
    watch: {
      dialogAdd(v) {
        if (v === false) {
          this.iptId = null;
          this.iptName = '';
          this.iptAmount = '';
        }
      },
    },
  }
</script>

<style scoped>

</style>
