<template>
  <v-container>
    <v-card>
      <v-card-title>Novo chamado</v-card-title>
      <v-card-subtitle>Preencha as informações para iniciar seu chamado de suporte</v-card-subtitle>
      <v-card-text>
        <v-form ref="form-chamado" :disabled="loading">
          <v-text-field
            label="Título"
            placeholder="Ex: Instalar impressora"
            outlined
            :rules="[rules.obrigatorio]"
            v-model="iptTitulo"
          ></v-text-field>
          <v-textarea
            label="Qual a solicitação?"
            placeholder="Descreva detalhadamente aqui..."
            :rows="5"
            outlined
            :rules="[rules.obrigatorio]"
            v-model="iptDescricao"
          ></v-textarea>
          <v-select
            label="Prioridade"
            hint="Pode ser alterado pelo técnico"
            persistent-hint
            :items="prioridades"
            outlined
            :rules="[rules.obrigatorio]"
            v-model="iptPrioridade"
          ></v-select>
          <v-alert
            v-if="iptPrioridade === 2"
            type="error"
          >Não use essa prioridade se não coincidir com o grau do incidente</v-alert>
        </v-form>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="primary" :loading="loading" @click="salvarChamado">SALVAR</v-btn>
        <v-btn to="/suporte" :disabled="loading">CANCELAR</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import SuporteWebClient from "@/http/SuporteWebClient";

export default {
  name: 'ChamadoEditor',
  data: () => ({
    iptTitulo: '',
    iptDescricao: '',
    iptPrioridade: 0,
    prioridades: [
      {text: 'Normal', value: 0},
      {text: 'Urgente (Impede o exercício da sua função)', value: 1},
      {text: 'Urgentíssimo (Paraliza todas as atividades do seu setor)', value: 2},
    ],
    rules: {
      obrigatorio: v => !!v || v === 0 || 'Preencha o campo acima.',
    },
    loading: false,
    webClient: new SuporteWebClient(),
  }),
  methods: {
    async salvarChamado() {
      if (!this.$refs['form-chamado'].validate()) return;
      this.loading = true;
      try {
        await this.webClient.insertChamado(this.iptTitulo, this.iptDescricao, this.iptPrioridade);
        this.$snackbar({text: 'Chamado registrado', color: 'success'});
        this.$router.push('/suporte/chamados');
      } finally {
        this.loading = false;
      }
    }
  },
}
</script>
