<template>
  <async-container fluid :loading="!loaded">
    <v-card>
      <v-card-title class="title-bar">Todos os chamados</v-card-title>
      <v-card-text>
        <v-select
          v-model="iptStatus"
          label="Filtrar por Status"
          :items="iptStatusItems"
        ></v-select>
      </v-card-text>
      <v-data-table
        no-data-text="Nenhum chamado encontrado"
        no-results-text="Nenhum chamado encontrado"
        :headers="headers"
        :items="itemsFiltered"
        :sort-by="['status', 'criacao']"
        :sort-desc="[false, true]"
        :footer-props="{'items-per-page-options': [10, 25, 50, 100]}"
        multi-sort
        dense
      >
        <template v-slot:item.id="{item}">
          <v-btn color="primary" text :to="'/suporte/chamado/' + item.id" small>{{item.id}}</v-btn>
        </template>
        <template v-slot:item.titulo="{item}">{{item.titulo.toUpperCase().trim()}}</template>
        <template v-slot:item.criacao="{item}">
          <div class="text-no-wrap">{{formatarData(item.criacao)}}</div>
        </template>
        <template v-slot:item.autor="{item}">
          {{shortName(item.autor_nome)}}
        </template>
        <template v-slot:item.prioridade="{item}">
          <v-chip small v-if="item.prioridade === 0" label color="primary">N</v-chip>
          <v-chip small v-else-if="item.prioridade === 1" label color="error">U</v-chip>
          <v-chip small v-else-if="item.prioridade === 2" label color="error">UU</v-chip>
        </template>
        <template v-slot:item.status="{item}">
          <v-chip label small v-if="item.status === 0" color="primary">EM ANDAMENTO</v-chip>
          <v-chip label small v-else-if="item.status === 1" color="success">ENCERRADO</v-chip>
          <v-chip label small v-else-if="item.status === 2">ARQUIVADO</v-chip>
        </template>
      </v-data-table>
    </v-card>
  </async-container>
</template>

<script>
import AsyncContainer from '@/components/interface/AsyncContainer';
import SuporteWebClient from '@/http/SuporteWebClient';
import moment from 'moment';
import StringHelper from '@/helpers/StringHelper';
export default {
  name: 'Fila',
  components: {AsyncContainer},
  data: () => ({
    headers: [
      {text: 'Protocolo', value: 'id'},
      {text: 'Assunto', value: 'titulo'},
      {text: 'Data', value: 'criacao', align: 'center'},
      {text: 'Autor', value: 'autor'},
      {text: 'Prioridade', value: 'prioridade', align: 'center'},
      {text: 'Status', value: 'status', align: 'center'},
    ],
    items: [],
    loaded: false,
    webClient: new SuporteWebClient(),
    iptStatusItems: [
      {value: -1, text: 'TODOS'},
      {value: 0, text: 'EM ANDAMENTO'},
      {value: 1, text: 'ENCERRADO'},
      {value: 2, text: 'ARQUIVADO'},
    ],
    iptStatus: 0,
  }),
  computed: {
    itemsFiltered() {
      if (this.iptStatus === -1) return this.items;
      else return this.items.filter(i => i.status === this.iptStatus);
    },
  },
  methods: {
    async loadData() {
      try {
        this.items = await this.webClient.getChamados(true);
      } catch (e) {
        this.$router.push('/suporte');
      } finally {
        this.loaded = true;
      }
    },
    formatarData(datetime) {
      return moment(datetime).format('DD/MM/YYYY HH:mm');
    },
    shortName(name) {
      return StringHelper.shortName(name);
    },
  },
  created() {
    this.loadData();
  },
}
</script>

<style scoped>
  .title-bar {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dbdbdb !important;
    padding: .75rem;
    margin-bottom: 1rem;
  }
</style>
