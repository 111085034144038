var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('async-container',{attrs:{"fluid":"","loading":!_vm.loaded}},[_c('v-card',[_c('v-card-title',{staticClass:"title-bar"},[_vm._v("Todos os chamados")]),_c('v-card-text',[_c('v-select',{attrs:{"label":"Filtrar por Status","items":_vm.iptStatusItems},model:{value:(_vm.iptStatus),callback:function ($$v) {_vm.iptStatus=$$v},expression:"iptStatus"}})],1),_c('v-data-table',{attrs:{"no-data-text":"Nenhum chamado encontrado","no-results-text":"Nenhum chamado encontrado","headers":_vm.headers,"items":_vm.itemsFiltered,"sort-by":['status', 'criacao'],"sort-desc":[false, true],"footer-props":{'items-per-page-options': [10, 25, 50, 100]},"multi-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","text":"","to":'/suporte/chamado/' + item.id,"small":""}},[_vm._v(_vm._s(item.id))])]}},{key:"item.titulo",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.titulo.toUpperCase().trim()))]}},{key:"item.criacao",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatarData(item.criacao)))])]}},{key:"item.autor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.shortName(item.autor_nome))+" ")]}},{key:"item.prioridade",fn:function(ref){
var item = ref.item;
return [(item.prioridade === 0)?_c('v-chip',{attrs:{"small":"","label":"","color":"primary"}},[_vm._v("N")]):(item.prioridade === 1)?_c('v-chip',{attrs:{"small":"","label":"","color":"error"}},[_vm._v("U")]):(item.prioridade === 2)?_c('v-chip',{attrs:{"small":"","label":"","color":"error"}},[_vm._v("UU")]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 0)?_c('v-chip',{attrs:{"label":"","small":"","color":"primary"}},[_vm._v("EM ANDAMENTO")]):(item.status === 1)?_c('v-chip',{attrs:{"label":"","small":"","color":"success"}},[_vm._v("ENCERRADO")]):(item.status === 2)?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v("ARQUIVADO")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }